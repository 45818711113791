import React from 'react';
import ReactDOM from 'react-dom/client';
import "./assets/app.css"
import AppBonusTalep from "./AppBonusTalep";
import {App, ConfigProvider, theme} from "antd";

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
    <ConfigProvider
        theme={{
            token: { colorPrimary: "#eb2a49" },
            algorithm: theme.darkAlgorithm
        }}>
        <App style={{height:"100%"}}>
            <AppBonusTalep />
        </App>
    </ConfigProvider>);
