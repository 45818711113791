import React, {useCallback, useEffect, useState} from "react";
import {ConfigProvider, Tabs, theme, App, Button, Segmented, Checkbox, Form, Input, Popconfirm, Row, Col} from "antd";
import Logo from "./assets/logo.gif";
import {isMobile} from "react-device-detect";
import {CheckOutlined, FireOutlined, GlobalOutlined, StarOutlined, SendOutlined, CheckCircleOutlined, UserOutlined, FieldTimeOutlined, CloseCircleOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import animationData from './assets/uQDCwm9vHL.lottie'


const CardComponent = ({type, title, subTitle, id, category, backgroundImage,notification,GetBonusTalepAction}) => {

    const [getLoading,setLoading] = useState(false);


    return (<Col span={isMobile ? 24 : {
        1: 24,
        2: 12,
        3: 24
    }[type]}>
        <div
            key={`${id}-${category}`}
            style={{
                borderRadius: 10,
                overflow: "hidden",
                boxShadow: "rgb(255 255 255 / 19%) 0px 0px 9px",
                background: {
                    1: `url(${backgroundImage})`,
                    2: `url(${backgroundImage})`,
                    3: `linear-gradient(90deg, rgba(69,0,0,0.2259497549019608) 0%, rgba(253,29,29,0.30438112745098034) 50%, rgba(252,176,69,0.12230829831932777) 100%)`
                }[type],
                backgroundRepeat:"no-repeat",
                backgroundSize:"100% 100%",
                height: {
                    1: 150,
                    2: 150,
                    3: 80
                }[type],
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                padding:"0px 10px"

            }}>
            <div style={{display: "flex", flexDirection: "column"}}>
                    <span style={{
                        fontSize: {
                            1: 34,
                            2: 34,
                            3: 24
                        }[type],
                        fontWeight: 900,
                        textShadow: "0 0 9px #ffffffc9",
                        WebkitTextStroke: "1px #030303a3",
                        color: "white",
                    }}>{title}</span>
                <span style={{fontSize: {
                        1: 24,
                        2: 24,
                        3: 14
                    }[type], fontWeight: 100}}>{subTitle}</span>
            </div>
            <div>
                <Popconfirm
                    placement={"topRight"}
                    title="Talep Onayı"
                    disabled={getLoading}
                    description="Ücretsiz Free Spin talebini onaylıyor musunuz?"
                    onConfirm={() => {

                        setLoading(true)
                        const formdata = new FormData();
                        formdata.append("api_key", "f8346532-2b4e-4c80-a449-2a68c0785f4b");
                        formdata.append("bonus_id", "1");
                        formdata.append("user_name", "asas");

                        const requestOptions = {
                            method: "POST",
                            body: formdata,
                            redirect: "follow"
                        };

                        fetch("https://emailsystem365.com/api/bonus/add", requestOptions)
                            .then((response) => response.json())
                            .then((result) => {
                                if(result.status !== 1){
                                    throw new Error(result.msg);
                                }

                                notification.success({description: result.msg, message:"BAŞARILI", icon: <CheckCircleOutlined  style={{fontSize:30, color:"#01d612"}}/>})

                                GetBonusTalepAction();
                                setLoading(false)


                            })
                            .catch((error) => {
                                setLoading(false)
                                notification.success({description: error.message, message:"HATA", icon: <CloseCircleOutlined  style={{fontSize:30, color:"#d60101"}}/>})
                            });

                    }}
                    onCancel={() => { }}
                    okText="Evet"
                    cancelText="Hayır">
                    <Button loading={getLoading} icon={<SendOutlined/>} type={"primary"} shape={"round"}>Talep Et</Button>
                </Popconfirm>
            </div>
        </div>
    </Col>)

};



const CardListing = ({getBonusCat,getBonus,notification,GetBonusTalepAction}) => {

    return (<Row gutter={[10,10]}>


        {
            (getBonusCat === "all" ? getBonus : getBonus.filter(item => item.bonus_type === getBonusCat) )?.length === 0 &&
            <div style={{width: "100%"}}>

                <DotLottieReact
                    src={animationData}
                    loop
                    autoplay
                    style={{width:"100%",height:300}}
                />

                <span style={{width: "100%", height: "40px", display: "flex", alignItems: "center", justifyContent: "center", fontSize: "29px", marginTop: "25px", paddingBottom: "20px"}}>Bonus Bulunamadı</span>

            </div>
        }

        {
            (getBonusCat === "all" ? getBonus : getBonus.filter(item => item.bonus_type === getBonusCat))?.map((item, index) => {

                var numberView;
                if (index === 0) {
                    numberView = 1;
                } else if (index === 1){
                    numberView = 2;
                }else if(index === 2){
                    numberView = 2;
                }else{
                    numberView = 3;
                }

                return <CardComponent
                    GetBonusTalepAction={GetBonusTalepAction}
                    notification={notification}
                    type={numberView}
                    id={1}
                    title={"ÜCRETSİZ"}
                    subTitle={"Freee Spin"}
                    category={"Casino"}
                    backgroundImage={"https://cdn.nexova.io/087036e4ac826b18d40ebcbbe48be1b5-main-bonus.png"}/>
            })
        }

    </Row>)

};

const BonusListMap = ({setBonusCat,getBonusCat, getBonus,notification,GetBonusTalepAction}) => (
    <Tabs
        onChange={e => setBonusCat(e)}
        activeKey={getBonusCat}
        items={[
            {
                key: `all`,
                label: `Hepsi`,
                children: <CardListing getBonusCat={getBonusCat} getBonus={getBonus} notification={notification} GetBonusTalepAction={GetBonusTalepAction}/>,
                icon: <GlobalOutlined/>,
            }, {
                key: `casino`,
                label: `Casino`,
                children: <CardListing getBonusCat={getBonusCat} getBonus={getBonus} notification={notification} GetBonusTalepAction={GetBonusTalepAction}/>,
                icon: <CheckOutlined/>,
            }, {
                key: `ekstra`,
                label: `Ekstra`,
                children: <CardListing getBonusCat={getBonusCat} getBonus={getBonus} notification={notification} GetBonusTalepAction={GetBonusTalepAction}/>,
                icon: <StarOutlined/>,
            }, {
                key: "spor",
                label: `Spor`,
                children: <CardListing getBonusCat={getBonusCat} getBonus={getBonus} notification={notification} GetBonusTalepAction={GetBonusTalepAction}/>,
                icon: <FireOutlined/>,
            },
        ]}
    />
)



const Status = {
    approved: "ONAYLANDI",
    waiting:"BEKLEMEDE",
    declined1:"RED 1",
    declined2:"RED 2",
    declined3:"RED 3",
    declined4:"RED 4",
    declined5:"RED 5"
};

const StatusIcon = {
    approved:   <CheckCircleOutlined  style={{fontSize:30, color:"#01d612"}}/>,
    waiting:   <FieldTimeOutlined style={{fontSize:30, color:"#ff7400"}} />,
    declined1: <CloseCircleOutlined style={{fontSize:30, color:"#e30505"}}/>,
    declined2: <CloseCircleOutlined style={{fontSize:30, color:"#e30505"}}/>,
    declined3: <CloseCircleOutlined style={{fontSize:30, color:"#e30505"}}/>,
    declined4: <CloseCircleOutlined style={{fontSize:30, color:"#e30505"}}/>,
    declined5: <CloseCircleOutlined style={{fontSize:30, color:"#e30505"}} />
};

// eslint-disable-next-line react-hooks/exhaustive-deps
const GetBonusListComponent = ({getBonusTalep}) => {


    return(<div style={{width:"calc(100% - 30px)", padding:15, display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column", gap:20}}>
        {
            getBonusTalep.map(item => {


                return (<div style={{width: "calc(100% - 30px)",  background: "#141414", borderRadius: 10, padding:15, display:"flex", flexDirection:"row"}}>

                    <div style={{flex:1}}>
                        <div style={{
                            flex: 1,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <div style={{flex: 1}}>Bonus Adı</div>
                            <div style={{flex: 1}}>{item.bonus.title_1}  {item.bonus.title_2}</div>
                        </div>

                        <div style={{
                            flex: 1,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <div style={{flex: 1}}>Talep Tarihi</div>
                            <div style={{flex: 1}}>{dayjs(item.created_at).format("DD/MM/YYYY HH:MM")}</div>
                        </div>

                        <div style={{
                            flex: 1,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <div style={{flex: 1}}>Talep Tipi</div>
                            <div style={{flex: 1}}>{item.bonus.bonus_type.toUpperCase()}</div>
                        </div>

                    </div>
                    <div style={{width:isMobile ? 70 : 120, display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", gap:25}}>
                        {StatusIcon[item.status] ?? "BİLİNMİYOR"}
                        <span>{Status[item.status] ?? "BİLİNMİYOR"}</span>
                    </div>

                </div>)
            })
        }

    </div>)

}


const AppBonusTalep = () => {


    const  { modal, message, notification} = App.useApp();

    const [getUsername,setUsername] = useState(false);
    const [getBonus,setBonus] = useState([]);
    const [getBonusCat,setBonusCat] = useState("all");
    const [getBonusTalep,setBonusTalep] = useState([]);
    const [getPage,setPage] = useState("Bonuslar");

    const GetBonusList = () => {

        const requestOptions = {
            method: "GET",
            redirect: "follow"
        };

        fetch("https://emailsystem365.com/api/bonus/list?api_key=f8346532-2b4e-4c80-a449-2a68c0785f4b", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setBonus(result.data);
            })
            .catch((error) => console.error(error));

    }

    const GetBonusTalepAction = () => {

        if(!getUsername)
            return;

        const formdata = new FormData();
        formdata.append("api_key", "f8346532-2b4e-4c80-a449-2a68c0785f4b");
        formdata.append("user_name", getUsername);

        const requestOptions = {
            method: "POST",
            body: formdata,
            redirect: "follow"
        };

        fetch("https://emailsystem365.com/api/bonus/check", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setBonusTalep(result.data);
            })
            .catch((error) => console.error(error));

    }


    useEffect(() => {

        GetBonusTalepAction();

        setInterval(() => {
            GetBonusTalepAction();
        },2000)

        GetBonusList();


    }, [getUsername]);


    const onFinish = (values) => {

        if (values?.username?.length < 4) {
            notification.error({description: "Kullanıcı Adını Hatalı", message: "HATA", placement: "bottom"});
            return
        }

        setUsername(values.username);

    };
    const onFinishFailed = (errorInfo) => {
        notification.error({description: "Kullanıcı Adını Giriniz", message: "HATA", placement: "bottom"});
    };

    if (!getUsername) {
        return (<App style={{height: "100%"}}>
            <div style={{display: "flex", height: "100%", width: "100%", flexDirection: "column"}}>
                <div style={{
                    width: "100%",
                    background: "#101010",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingTop: isMobile ? 25 : 25,
                    paddingBottom: 25
                }}>
                    <img src={Logo} height={60}/>

                </div>

                <div style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}>

                    <div style={{width: isMobile ? "70%" : 350,  background:"#101010", borderRadius:10, padding:50, flexDirection:"column", display:"flex", alignItems:"center", justifyContent:"center"}}>
                       <span style={{fontSize:18, marginTop:10, paddingBottom:45}}>
                           <strong>ROMABET</strong> <span style={{fontWeight:100}}>BONUS TALEP SİSTEMİ</span>
                       </span>
                        <Form
                            onFinishFailed={onFinishFailed}
                            style={{width: isMobile ? "100%" : 350}}
                            name="login"
                            layout="vertical"
                            onFinish={onFinish}>
                            <Form.Item
                                name="username"
                                label={<label style={{ color: "white" }}>Kullanıcı Adı</label>}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Kullanıcı adınızı giriniz!',
                                    }
                                ]}>
                                <Input prefix={<UserOutlined />} placeholder="Kullanıcı Adı Giriniz" />
                            </Form.Item>
                            <Form.Item>
                                <Button block type="primary" htmlType="submit"> Deval Et </Button>
                            </Form.Item>
                        </Form>

                    </div>

                </div>

            </div>
        </App>)
    }



    return (
            <div style={{flex: 1, display: "flex", height: "100%", width: "100%", flexDirection: "column"}}>
                <div style={{
                    width: "100%",
                    background: "#101010",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingTop: isMobile ? 25 : 25,
                    paddingBottom: 25
                }}>
                    <img src={Logo} height={60}/>

                </div>
                <div  style={{flex: 1, display: "flex", alignItems: "flex-start", marginTop:  isMobile ? 10 : 25, justifyContent: "center"}}>


                    <div style={{width: isMobile ? "95%" : 650, paddingBottom: 30, paddingTop: isMobile ? 0 : 25}}>


                        <div style={{background: "#00000059", padding: 15, borderRadius: 15}}>

                            <span style={{fontSize: 18, marginTop: 10, paddingBottom: 45}}>
                               <strong>ROMABET</strong> <span style={{fontWeight: 100}}>BONUS TALEP SİSTEMİ</span>
                            </span>

                            <Segmented defaultValue={"Bonuslar"} options={["Bonuslar", "Talepler"]} block onChange={e => setPage(e)}  />

                            {
                                getPage === "Bonuslar" && <BonusListMap setBonusCat={setBonusCat} getBonusCat={getBonusCat} getBonus={getBonus} notification={notification} GetBonusTalepAction={GetBonusTalepAction}/>
                            }
                            {
                                getPage === "Talepler" && <GetBonusListComponent getBonusTalep={getBonusTalep}/>
                            }



                        </div>

                    </div>

                </div>
            </div>)
}


export default AppBonusTalep;